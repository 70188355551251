import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, Row } from "./styles";

const MailChimpForm = ({ onValidated }) => {
    const [submitting, setSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const onSubmit = data => {
        setSubmitting(true);
        onValidated({
            EMAIL: data.email,
            "group[10677][2]": "1",
        });
        setSubmitting(false);
        reset();
    };

    return (
        <>
            <Row>
                <FormContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            {...register("email", {
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            placeholder="Your email for e-Newsletter of KOFFIA"
                        />

                        <input
                            type="submit"
                            value="Subscribe"
                            disabled={!!errors.email || submitting}
                        />
                    </form>
                    {/* {errors.email && <span>Email address is required</span>} */}
                </FormContainer>
            </Row>
        </>
    );
};

export default MailChimpForm;
