export const colors = {
    primary: "#d82f36",
    light: "#F7FAFC",
    secondary: "#f5f5f5",
    lightGray: "#EDF2F7",
    gray: "#efeaea",
    lightDark: "#242424",
    darkGray: "#a3a3a3",
    lightBlue: "#163961",
    dark: "#333",
};

export const breakpoints = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
};
