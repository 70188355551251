import React, { useState } from "react";
import SubMenu from "./SubMenuItem";
import LogoImg from "./LogoImage";
import { menus } from "./menulist";
import {
    HeaderTop,
    Container,
    LogoContainer,
    Logo,
    LogoTitle,
    NavigationContainer,
    Navigation,
    Menu,
    StyledLink,
    SubMenuContainer,
    MenuButtonContainer,
    Hamberger,
    HambergerButton,
    StyledSubMenuContainer,
    CanvasOverlay,
} from "./styles";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [
        showSubMenuBackDropDesktop,
        setShowSubMenuBackDropDesktop,
    ] = useState(false);
    const [showSubMenuDesktop, setShowSubMenuDesktop] = useState(false);
    const [showSubMenuBackdropMobile, setshowSubMenuBackdropMobile] = useState(
        true
    );
    const [mobile, setMobile] = useState(false);
    const [activeSubMenuMobile, setActiveSubMenuMobile] = useState();

    const onMouseEnter = () => {
        if (!showSubMenuBackDropDesktop) {
            setShowSubMenuBackDropDesktop(true);
        }
        if (!showSubMenuDesktop) {
            setShowSubMenuDesktop(true);
        }
    };
    const onMouseLeave = () => {
        if (showSubMenuBackDropDesktop) {
            setShowSubMenuBackDropDesktop(false);
        }
        if (showSubMenuDesktop) {
            setShowSubMenuDesktop(false);
        }
    };

    const onClickMenuButton = () => {
        setMobile(true);
        showMenu ? setShowMenu(false) : setShowMenu(true);
        showSubMenuBackdropMobile
            ? setshowSubMenuBackdropMobile(false)
            : setshowSubMenuBackdropMobile(true);
    };

    const onClickOverlay = () => {
        setShowMenu(false);
        setshowSubMenuBackdropMobile(false);
    };

    const handleMenuClick = e => {
        const id = e.target.id;

        if (mobile) {
            setActiveSubMenuMobile(id);
        }
    };

    return (
        <>
            <HeaderTop>
                <Container>
                    <LogoContainer to="/">
                        <Logo>
                            <LogoImg />
                        </Logo>
                        <LogoTitle>
                            <div>Korean Film Festival</div>
                            <div>in Australia</div>
                        </LogoTitle>
                    </LogoContainer>
                    <NavigationContainer show={showMenu}>
                        <Navigation>
                            {menus.map((list, index) => {
                                return (
                                    <Menu key={list.id}>
                                        <StyledLink
                                            title={list.title}
                                            id={list.id}
                                            to={list.to}
                                            onClick={handleMenuClick}
                                        >
                                            {list.title}
                                        </StyledLink>
                                    </Menu>
                                );
                            })}
                        </Navigation>
                    </NavigationContainer>
                    <MenuButtonContainer show={showMenu}>
                        <HambergerButton
                            type="checkbox"
                            onChange={onClickMenuButton}
                            checked={showMenu}
                        />
                        <Hamberger show={showMenu} />
                        <Hamberger show={showMenu} />
                        <Hamberger show={showMenu} />
                    </MenuButtonContainer>
                </Container>
                <StyledSubMenuContainer show={showSubMenuBackDropDesktop} />
            </HeaderTop>
            <CanvasOverlay show={showMenu} onClick={onClickOverlay} />
        </>
    );
};

export default Header;
