import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";

export const FooterContainer = styled.div`
    background: ${colors.lightDark};
    color: #fff;
    margin-top: 0rem;
`;
export const FooterBox = styled.div`
    max-width: ${breakpoints.xl};
    margin: 0 auto;
    padding: 1rem 0 3rem;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;
export const ColLeft = styled.div`
    padding-top: 1rem;
    order: 1;
    @media (min-width: ${breakpoints.md}) {
        padding-top: 2rem;
        flex: 1;
        order: 0;
    }
`;
export const ColCenter = styled.div`
    padding-top: 1rem;
    @media (min-width: ${breakpoints.md}) {
        padding-top: 2rem;
        flex: 1;
    }
`;
export const ColRight = styled.div`
    padding-top: 1rem;
    @media (min-width: ${breakpoints.md}) {
        padding-top: 2rem;
        margin-left: 0;
        flex: 1;
    }
`;
export const Copyright = styled.div`
    padding-top: 2rem;
    text-align: center;
    width: 100%;
    font-size: 0.625rem;
    font-weight: 300;
    @media (min-width: ${breakpoints.sm}) {
        font-size: 0.875rem;
    }
`;
export const SubTitle = styled.h2`
    font-size: 1.7rem;
    text-align: center;
    font-weight: 300;
`;
export const SocialRow = styled.div`
    display: flex;
    justify-content: flex-start;
    @media (min-width: ${breakpoints.md}) {
        margin: 0rem 0.5rem 1rem;
    }
    @media (min-width: ${breakpoints.lg}) {
        margin: 0rem auto 1rem 0;
    }
`;
export const LogoImgContainer = styled.div`
    display: block;
    width: 12.5rem;
    margin: 0 auto 0 0;
`;
const IconStyle = css`
    font-size: 1.25rem;
    margin: 0.5rem 1rem;
    color: #fff;
`;
export const FacebookIcon = styled(FaFacebookF)`
    ${IconStyle}
`;
export const InstagramIcon = styled(FaInstagram)`
    ${IconStyle}
`;
export const TwitterIcon = styled(FaTwitter)`
    ${IconStyle}
`;
export const YoutubeIcon = styled(FaYoutube)`
    ${IconStyle}
`;
export const StyledIconA = styled.a`
    font-size: 1.25rem;
    color: ${colors.dark};
    &:hover {
        color: ${colors.primary};
    }
`;
export const StyledLink = styled(Link)`
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    &:hover {
        color: ${colors.primary};
    }
`;
export const StyledAnchor = styled.a`
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    &:hover {
        color: ${colors.primary};
    }
`;
export const InfoContainer = styled.div`
    width: 17.25rem;
    margin: 2rem auto;
    @media (min-width: ${breakpoints.md}) {
        width: 15rem;
        margin: 0.5rem 0 auto auto;
    }
`;
export const InfoBox = styled.div`
    text-align: center;
    margin: 0.625rem auto;
    @media (min-width: ${breakpoints.md}) {
        text-align: left;
    }
`;
export const InfoText = styled.span`
    font-weight: 300;
    font-size: 0.875rem;
    @media (min-width: ${breakpoints.sm}) {
        margin-left: 1rem;
        font-size: 1rem;
    }
`;
export const SiteMapBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0;
    min-width: 19rem;
`;
export const SiteInfo = styled.div`
    width: 33.3%;
    text-align: center;
    margin: 0.25rem auto;
`;
export const AddressText = styled.span`
    display: block;
    font-weight: 300;
    font-size: 0.875rem;
    @media (min-width: ${breakpoints.sm}) {
        margin-left: 1rem;
        font-size: 1rem;
    }
`;
