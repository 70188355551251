import React from "react";
import styled, { keyframes } from "styled-components";
import { colors, breakpoints } from "../../styles/variables";
import { Link } from "gatsby";

export const HeaderTop = styled.header`
    background-color: #242424;
    position: relative;
    margin: 0;
    padding: 0 1rem;
    border-bottom: #242424 solid 1px;
    @media (min-width: ${breakpoints.xl}) {
        padding: 0;
        position: sticky;
        top: 0;
        z-index: 10;
    }
`;

export const Container = styled.div`
    max-width: ${breakpoints.xl};
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 5;
    padding: 1rem 0;
    @media (min-width: ${breakpoints.xl}) {
        margin: 0 auto;
    }
`;

export const LogoTitle = styled.div`
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 0.8rem;
    margin: auto 0 auto 0.5rem;

    @media (min-width: ${breakpoints.xl}) {
        font-size: 0.8rem;
        line-height: 1rem;
    }
`;

export const Logo = styled.div`
    width: 2rem;

    @media (min-width: ${breakpoints.xl}) {
        width: 3rem;
        margin: auto 0;
    }
`;

export const LogoContainer = styled(Link)`
    display: flex;
    color: #fff;
    text-decoration: none;
    &:hover {
        color: ${colors.primary};
    }
`;

export const slideAnimationX = keyframes`
    from {
        opacity: 0; transform: translateX(100%);   
    }
    to {
        opacity: 1; transform: translateX(0); 
    }
`;

export const FadeAnimation = keyframes`
    from {
        opacity:0;
    }
    to{
        opacity:0.6;
    }
`;

export const slideDown = keyframes`
    from {
        opacity: 0; transform: translateY(-10%);   
    }
    to {
        opacity: 1; transform: translateY(0); 
    }
`;

export const slideUp = keyframes`
from {
    opacity: 0; transform: translateY(100%);   
}
to {
    opacity: 1; transform: translateY(0); 
}
`;

export const slideOut = keyframes`
    from {
        opacity: 1; transform: translateX(0);   
    }
    to {
        opacity: 0; transform: translateX(100%); 
    }
`;

export const NavigationContainer = styled.div`
    position: fixed;
    z-index:998;
    width: 15rem;
    height:calc(100vh + 2rem);
    padding: 2rem;  
    top: -2rem; 
    right: 0px;
    background-color: ${colors.dark};   
    animation: ${(props: { show: boolean }) =>
        props.show ? slideAnimationX : slideOut}  0.3s ease-in-out;
 
    ${(props: { show: boolean }) =>
        props.show ? `display:block;` : `display:none;`}  
    
    @media(min-width:${breakpoints.sm}){
        width:20rem;
    }

    @media(min-width:${breakpoints.xl}){
        display:block; 
        position:relative;
        top: 0;
        right: 0;
        padding: 0;
        height: auto; 
        width: 80%;
        background-color: #242424;
        animation:none;
    }
`;
export const Navigation = styled.ul`
    display: flex;
    justify-content: flex-end;
    list-style: none;
    align-items: flex-start;
    flex-flow: column;
    padding: 2rem 0;
    margin-top: 2rem;
    margin-bottom: 0;

    @media (min-width: ${breakpoints.xl}) {
        flex-flow: row;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin-top: 0;
    }
`;

export const Menu = styled.li`
    display: block;
    margin: auto 0;
    padding-bottom: 1.5rem;
    &:hover {
        color: ${colors.primary};
    }

    @media (min-width: ${breakpoints.xl}) {
        color: #fff;
        display: inline-block;
        padding-bottom: 0;
        &:last-child {
            margin-right: -3.5rem;
        }
    }
`;

export const StyledLink = styled(props => <Link {...props} />)`
    text-decoration: none;
    margin: auto 3rem auto 0;
    color: #fff;
    letter-spacing: 1px;
    font-size: 1rem;
    height: 100%;
    width: 100%;
    &:hover {
        color: ${colors.primary};
    }
    @media (min-width: ${breakpoints.sm}) {
        margin: auto 4rem auto 0;
    }
    @media (min-width: ${breakpoints.xl}) {
        color: #fff;
        font-size: 1.2rem;
    }
`;

export const StyledSubMenuContainer = styled.div`
    background-color: #242424;
    width: 100%;
    height: 10.5rem;
    display: none;
    animation: ${slideDown} 0.3s ease-in-out;
    border-bottom: 1px #242424 solid;

    @media (min-width: ${breakpoints.xl}) {
        z-index: 1;
        position: absolute;
        ${(props: { show: boolean }) =>
            props.show ? `display:block;` : `display:none;`}
        position: absolute;
        top: 120px;
        z-index: 1;
    }
`;

export const MenuButtonContainer = styled.div` 
  display: block; 
  ${(props: { show: boolean }) =>
      props.show
          ? `position:fixed; right: 1rem; margin-right: 0;`
          : `position: relative;
  margin-right: 0rem; `}
  -webkit-user-select: none;
  user-select: none; 
  z-index: 1000;
  @media(min-width: ${breakpoints.xl}){
      display:none; 
  } 
} 
`;

export const HambergerButton = styled.input`
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;

    &:checked ~ span {
        transform: rotate(-45deg) translate(0, -1px);
    }
    &:checked ~ span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:checked ~ span:nth-last-child(3) {
        transform: rotate(45deg) translate(1.5px, -1px);
        opacity: 1;
    }
`;

export const Hamberger = styled.span`
    display: block;
    border-radius: 1px;
    width: 2rem;
    height: 3px;
    margin-bottom: 6px;
    position: relative;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    ${(props: { show: boolean }) =>
        props.show ? `background-color: #eee; ` : `background-color: #eee ;`}

    &:first-child {
        transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
        transform-origin: 0% 100%;
    }
`;

export const SubMenuContainer = styled.div`
    animation: ${slideDown} 0.5s ease-in-out;

    ${(props: { show: boolean }) =>
        props.show ? `display:block;` : `display:none;`}
        
    @media (min-width: ${breakpoints.xl}) {
        display:block! important;
    }
`;

export const CanvasOverlay = styled.div`
    position: fixed;
    background: rgb(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 997;
    transition: opacity 0.3s ease;
    animation: ${FadeAnimation} 0.3s ease-in;
    ${(props: { show: boolean }) =>
        props.show ? `display:block;` : `display:none;`}
        
    @media(min-width:${breakpoints.xl}){
        display:none !important; 
    }
`;
