import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

export const FormContainer = styled.div`
    width: 100%;
    max-width: ${breakpoints.xl};
    margin: 0 auto;
    padding: 4rem 2rem 1rem;
    form {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        @media (min-width: ${breakpoints.md}) {
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
        }
    }
    span {
        color: ${colors.primary};
        text-align: end;
        width: 100%;
        display: block;
    }

    span::before {
        display: inline;
        content: "⚠ ";
    }

    input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        border-radius: 4px;
        border: 1px solid white;
        padding: 10px 15px;
        margin-bottom: 10px;
        font-size: 14px;
        max-width: 24rem;
        @media (min-width: ${breakpoints.md}) {
            border-radius: 4px 0 0 4px;
        }
    }

    label {
        line-height: 2;
        text-align: left;
        display: block;
        margin-bottom: 13px;
        margin-top: 20px;
        color: white;
        font-size: 14px;
        font-weight: 200;
    }

    button[type="submit"],
    input[type="submit"] {
        background: ${colors.primary};
        color: white;
        border: none;
        padding: 0.5rem;
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 1px;
        max-width: 10rem;
        border-radius: 4px;
        @media (min-width: ${breakpoints.md}) {
            border-radius: 0 4px 4px 0;
        }
    }

    button[type="submit"]:hover,
    input[type="submit"]:hover {
        background: ${colors.lightBlue};
    }

    button[type="submit"]:active,
    input[type="button"]:active,
    input[type="submit"]:active {
        transition: 0.3s all;
        transform: translateY(3px);
        border: 1px solid transparent;
        opacity: 0.8;
    }

    input:disabled {
        opacity: 0.4;
    }

    input[type="button"]:hover {
        transition: 0.3s all;
    }

    button[type="submit"],
    input[type="button"],
    input[type="submit"] {
        -webkit-appearance: none;
    }
`;
