export const menus = [
    {
        title: "History",
        id: 0,
        to: "/history",
    },

    {
        title: "Contact",
        id: 4,
        to: "/contact",
    },
];
