import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import MailChimpForm from "./mailchimp-form";

const MailChimp = () => {
    const mailchimpUrl =
        "https://koreanculture.us2.list-manage.com/subscribe/post?u=5ef8569bb1fe335653593403d&amp;id=05420fdb95";

    return (
        <MailchimpSubscribe
            url={mailchimpUrl}
            render={({ subscribe, status, message }) => (
                <MailChimpForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    );
};

export default MailChimp;
